
    import axios from 'axios'
    import store from '../store/index.js'

    export default {
        async add_cities(params)  {
            return await axios.post('cities/create.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async get_cities()  {
            // return await axios.get('cities/read.php')
            // .then(r =>{
            //     return r
            // })
            // .catch(e =>{
            //     console.log(e);
            // })
            store.commit('get_cities')
        },
        async delete_cities(city_id)  {
            return await axios.post('cities/delete.php' , {
                city_id : city_id
            })
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async getOne(city_id)  {
            return await axios.post('cities/readOne.php' , {
                city_id : city_id
            })
            .then(r =>{
                return r
                
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async update_cities(params)  {
            return await axios.post('cities/update.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async report_cities(params)  {
            return await axios.post('cities/report.php',params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
    }
